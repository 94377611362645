define("discourse/plugins/discourse-locations/discourse/components/location-label-container", ["exports", "discourse-common/utils/decorators", "@glimmer/component", "@ember/object", "@ember/runloop", "@ember/service", "@glimmer/tracking", "discourse/components/d-button", "discourse-common/helpers/d-icon", "discourse/plugins/discourse-locations/discourse/helpers/location-format", "discourse-common/helpers/i18n", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/will-destroy", "discourse/plugins/discourse-locations/discourse/components/locations-map", "@ember/template-factory", "@ember/component"], function (_exports, _decorators, _component, _object, _runloop, _service, _tracking, _dButton, _dIcon, _locationFormat, _i18n, _didInsert, _willDestroy, _locationsMap, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let LocationLableContainerComponent = _exports.default = (_class = (_class2 = class LocationLableContainerComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
      _initializerDefineProperty(this, "site", _descriptor2, this);
      _initializerDefineProperty(this, "locationAttrs", _descriptor3, this);
      _initializerDefineProperty(this, "geoAttrs", _descriptor4, this);
      _initializerDefineProperty(this, "showMap", _descriptor5, this);
      _defineProperty(this, "outsideClick", e1 => {
        if (!this.isDestroying && !($(e1.target).closest(".map-expand").length || $(e1.target).closest(".map-attribution").length || $(e1.target).closest(".location-topic-map").length || $(e1.target).closest("#locations-map").length)) {
          this.showMap = false;
        }
      });
    }
    get mapButtonLabel() {
      return `location.geo.${this.showMap ? "hide" : "show"}_map`;
    }
    get showMapButtonLabel() {
      return !this.site.mobileView;
    }
    get showMapToggle() {
      return this.args.topic.location.geo_location && this.siteSettings.location_topic_map;
    }
    get opts() {
      let opts1 = {};
      if (this.locationAttrs) {
        opts1["attrs"] = this.locationAttrs;
      }
      if (this.geoAttrs) {
        opts1["geoAttrs"] = this.geoAttrs;
      }
      return opts1;
    }
    bindClick() {
      $(document).on("click", (0, _runloop.bind)(this, this.outsideClick));
    }
    unbindClick() {
      $(document).off("click", (0, _runloop.bind)(this, this.outsideClick));
    }
    toggleMap() {
      this.showMap = !this.showMap;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div {{didInsert this.bindClick}} {{willDestroy this.unbindClick}} class="location-label-container">
        <div class="location-label" title={{i18n "location.label.title"}}>
          {{icon "map-marker-alt"}}
          <span class="location-text">
            {{locationFormat this.args.topic.location this.opts}}
          </span>
        </div>
  
        {{#if this.showMapToggle}}
          <div class="location-topic-map">
            <DButton
              @action={{this.toggleMap}}
              class="btn btn-small btn-default"
              @icon="far-map"
              @label={{if this.showMapButtonLabel this.mapButtonLabel}}
            />
          </div>
          {{#if this.showMap}}
            <div class="map-component map-container small">
              <LocationsMap @topic={{this.args.topic}} @mapType="topic" />
            </div>
          {{/if}}
        {{/if}}
      </div>
    
  */
  {
    "id": "ifG7T045",
    "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"location-label-container\"],[4,[32,0],[[30,0,[\"bindClick\"]]],null],[4,[32,1],[[30,0,[\"unbindClick\"]]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"location-label\"],[15,\"title\",[28,[32,2],[\"location.label.title\"],null]],[12],[1,\"\\n        \"],[1,[28,[32,3],[\"map-marker-alt\"],null]],[1,\"\\n        \"],[10,1],[14,0,\"location-text\"],[12],[1,\"\\n          \"],[1,[28,[32,4],[[30,0,[\"args\",\"topic\",\"location\"]],[30,0,[\"opts\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showMapToggle\"]],[[[1,\"        \"],[10,0],[14,0,\"location-topic-map\"],[12],[1,\"\\n          \"],[8,[32,5],[[24,0,\"btn btn-small btn-default\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"toggleMap\"]],\"far-map\",[52,[30,0,[\"showMapButtonLabel\"]],[30,0,[\"mapButtonLabel\"]]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"],[41,[30,0,[\"showMap\"]],[[[1,\"          \"],[10,0],[14,0,\"map-component map-container small\"],[12],[1,\"\\n            \"],[8,[32,6],null,[[\"@topic\",\"@mapType\"],[[30,0,[\"args\",\"topic\"]],\"topic\"]],null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-locations/discourse/components/location-label-container.js",
    "scope": () => [_didInsert.default, _willDestroy.default, _i18n.default, _dIcon.default, _locationFormat.default, _dButton.default, _locationsMap.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "locationAttrs", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "geoAttrs", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showMap", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "bindClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "bindClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unbindClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "unbindClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleMap", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleMap"), _class.prototype)), _class);
  ;
});