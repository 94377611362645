define("discourse/plugins/discourse-locations/discourse/components/replace-location", ["exports", "@glimmer/component", "@ember/service", "discourse/helpers/body-class", "discourse-common/helpers/d-icon", "discourse/plugins/discourse-locations/discourse/components/user-location", "@ember/template-factory", "@ember/component"], function (_exports, _component, _service, _bodyClass, _dIcon, _userLocation, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ReplaceLocationComponent = _exports.default = (_class = (_class2 = class ReplaceLocationComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
    }
    get showUserLocation() {
      return !!this.args.model.custom_fields?.geo_location && this.args.model.custom_fields?.geo_location !== "{}";
    }
    get linkWebsite() {
      return !this.args.model.isBasic;
    }
    get removeNoFollow() {
      return this.args.model.trust_level > 2 && !this.siteSettings.tl3_links_no_follow;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{bodyClass "map-location-enabled"}}
      {{#if this.showUserLocation}}
        <div class="user-profile-location">
          <UserLocation @user={{@model}} @formFactor="profile" />
        </div>
      {{/if}}
      {{#if @model.website_name}}
        <div class="user-profile-website">
          {{icon "globe"}}
          {{#if this.linkWebsite}}
            <a
              href={{@model.website}}
              rel={{unless this.removeNoFollow "nofollow ugc noopener"}}
              target="_blank"
            >
              {{@model.website_name}}
            </a>
          {{else}}
            <span title={{@model.website}}>{{@model.website_name}}</span>
          {{/if}}
        </div>
      {{/if}}
    
  */
  {
    "id": "yel/Sb8d",
    "block": "[[[1,\"\\n    \"],[1,[28,[32,0],[\"map-location-enabled\"],null]],[1,\"\\n\"],[41,[30,0,[\"showUserLocation\"]],[[[1,\"      \"],[10,0],[14,0,\"user-profile-location\"],[12],[1,\"\\n        \"],[8,[32,1],null,[[\"@user\",\"@formFactor\"],[[30,1],\"profile\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[41,[30,1,[\"website_name\"]],[[[1,\"      \"],[10,0],[14,0,\"user-profile-website\"],[12],[1,\"\\n        \"],[1,[28,[32,2],[\"globe\"],null]],[1,\"\\n\"],[41,[30,0,[\"linkWebsite\"]],[[[1,\"          \"],[10,3],[15,6,[30,1,[\"website\"]]],[15,\"rel\",[52,[51,[30,0,[\"removeNoFollow\"]]],\"nofollow ugc noopener\"]],[14,\"target\",\"_blank\"],[12],[1,\"\\n            \"],[1,[30,1,[\"website_name\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,1],[15,\"title\",[30,1,[\"website\"]]],[12],[1,[30,1,[\"website_name\"]]],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@model\"],false,[\"if\",\"unless\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-locations/discourse/components/replace-location.js",
    "scope": () => [_bodyClass.default, _userLocation.default, _dIcon.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});